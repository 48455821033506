import React from 'react';
import InfoBox from '../info-box/InfoBox';
import { SectionWrapper } from '../../demand-generation/styles';

const InfoCardsFragmentWrapper = ({ data }) => {
	return (
		<div>
			{data.map(section => (
				<SectionWrapper>
					<InfoBox
						image={section.image}
						title={section.title}
						subtitle={section.subtitle.subtitle}
						invert={!section.keepImageOnRight}
						subHeading="true"
						videoBorderRadius={'10px'}
						header={section.header}
						link={section.button?.linkUrl}
						linkText={section.button?.linkText}
					/>
				</SectionWrapper>
			))}
		</div>
	);
};

export default InfoCardsFragmentWrapper;
