import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';

import InfoBox from '../components/global/info-box/InfoBox';
import Layout from '../components/global/layout/Layout';
import SEO from '../components/global/seo';
import WrapperOne from '../components/global/wrappers/WrapperOne';
import SectionTextBox from '../components/global/section-textbox/SectionTextBox';
import Snippet from '../components/global/snippet/Snippet';
import CTAWrapper from '../components/home-page/CtaWrapper/CTAWrapper';
import MasterCTA from '../components/global/CTA/MasterCTA/MasterCTA.component';
import Faq from '../components/global/Faq';

import { mediaQuery, setColors } from '../../utils/helpers';

import { Container } from '../components/global/customers/styles';
import { SectionWrapper } from '../components/mid-market/styles';
import {
	ImageHolder,
	Section,
} from '../components/home-page/about-chatbots/styles';
import {
	SliderContainer,
	SliderWrapper,
} from '../components/customer-support/styles';
import { WhatsappCTAWrapper } from '../components/whatsapp-chatbot/styles';
import VerticalSlider from '../components/global/ImageSlider/VerticalSlider';
import { sliderProps } from '../pages/bot-builder';
import InfoCardsFragmentWrapper from '../components/global/InfoCardsFragmentWrapper/InfoCardsFragmentWrapper';

const FAQWrapper = styled.div`
	background: ${setColors.warmGray};
	padding: 140px 0 0 0;
	margin: 100px 0 0 0;
	${mediaQuery.mobileXL`
		padding: 35px 0 0 0;
		margin: 55px 0 0 0;
	`}
`;

const newWhiteLabelTemplate = ({ data }) => {
	const {
		heroTitle,
		heroSubtitle,
		heroImage,
		buttonText,
		buttonUrl,
		section1Title,
		section1Subtitle,
		section1Image,
		section2Images,
		section3Title,
		section3Cards,
		cta1,
		section4Cards,
		section5Title,
		section5SubTitle,
		section5Features,
		faqSubtitle,
		faqTitle,
		faqQuestions,
		cta2,
		seo,
	} = data.contentfulPageNewWhiteLabelTemplate;

	return (
		<Layout>
			<SEO {...seo} />
			<WrapperOne>
				<InfoBox
					image={heroImage}
					title={heroTitle}
					subtitle={heroSubtitle}
					hideImage="true"
					buttonText={buttonText}
					buttonLink={buttonUrl}
					isHeroHeader
				/>
			</WrapperOne>
			<Section>
				<SectionTextBox
					title={section1Title}
					subtitle={section1Subtitle}
					largeHeading="true"
				/>
				<Img
					fluid={section1Image.fluid}
					alt={section1Image.title}
					style={{ maxWidth: '927px', margin: '0 auto', width: '100%' }}
				/>
			</Section>
			<Container>
				<SectionWrapper>
					<ImageHolder>
						{section2Images.map(info => (
							<Snippet
								url={info.file.url}
								title={info.title}
								subtitle={info.description}
							/>
						))}
					</ImageHolder>
				</SectionWrapper>

				<Section>
					<SectionTextBox title={section3Title} />
				</Section>

				<InfoCardsFragmentWrapper data={section3Cards} />

				<WhatsappCTAWrapper noBottomPadding={true}>
					<MasterCTA cta={cta1} />
				</WhatsappCTAWrapper>

				<InfoCardsFragmentWrapper data={section4Cards} />

				<SliderContainer>
					<SectionTextBox title={section5Title} subtitle={section5SubTitle} />
					<SliderWrapper>
						<VerticalSlider
							data={section5Features}
							noBottomPadding={true}
							{...sliderProps}
						/>
					</SliderWrapper>
				</SliderContainer>
			</Container>
			<FAQWrapper>
				<Container>
					<Faq title={faqTitle} subtitle={faqSubtitle} content={faqQuestions} />
				</Container>
			</FAQWrapper>
			<Container>
				<CTAWrapper>
					<MasterCTA cta={cta2} />
				</CTAWrapper>
			</Container>
		</Layout>
	);
};

export const query = graphql`
	query($slug: String) {
		contentfulPageNewWhiteLabelTemplate(slug: { eq: $slug }) {
			heroTitle
			heroSubtitle
			heroImage {
				...asset
			}
			buttonText
			buttonUrl
			section1Title
			section1Subtitle
			section1Image {
				...asset
			}
			section2Images {
				...asset
			}
			section3Title
			section3Cards {
				...infoSectionFragment
			}
			cta1 {
				...cta
			}
			section4Cards {
				...infoSectionFragment
			}
			section5Title
			section5SubTitle
			section5Features {
				title
				description
				fluid(maxHeight: 500, maxWidth: 747) {
					...GatsbyContentfulFluid
				}
			}
			faqSubtitle {
				raw
			}
			faqTitle
			faqQuestions {
				question
				answer {
					type
					content
				}
			}
			cta2 {
				...cta
			}
			seo {
				...seo
			}
		}
	}
`;

export default newWhiteLabelTemplate;
